import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import svgLogo from '../logo-teamticker-single-redwhite.svg';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import
{
	signOutUser,
	selectJWT,
	selectJWTObj,
	selectContentTab,
	selectRefreshToken,
	setContentTab,
} from '../store/appSlice'

function Header()
{

	const dispatch = useDispatch();

	const jwt = useSelector(selectJWT);
	const jwtObj = useSelector(selectJWTObj);
	const contentTab = useSelector(selectContentTab);

	const handleSignOut = () =>
	{
		dispatch(signOutUser());
	}

	const a11yProps = (index) =>
	{
		return {
			id: `full-width-tab-${index}`,
			'aria-controls': `full-width-tabpanel-${index}`,
		};
	}

	const handleChange = (event, newValue) =>
	{
		dispatch(setContentTab({ value: newValue }));
	};

	return <>
		<AppBar position="static">
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Box>
						<img src={svgLogo} style={{ height: 50 }} />
					</Box>

					<Box sx={{ flexGrow: 1, marginLeft: 5 }}>
						{
							jwt &&
							<Tabs
								value={contentTab}
								onChange={handleChange}
								textColor="inherit"
							>
								{
									jwtObj.requiresAccessToken &&
									<Tab value="gamedata" label="Publish" {...a11yProps(0)} />
								}
								<Tab value="summary" label="Upcoming Games" {...a11yProps(1)} />
								<Tab value="livescores" label="Live Scores" {...a11yProps(3)} />
								<Tab value="devices" label="Manage Signs" {...a11yProps(2)} />
							</Tabs>
						}
					</Box>

					<Box sx={{ flexGrow: 0, alignContent: 'bottom' }}>
						{
							jwt &&
							<>
								<Tooltip title="Sign Out">
									<Button onClick={() => { handleSignOut(); }} variant="outline">Sign Out</Button>
								</Tooltip>
							</>
						}
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	</>;
}

export default Header;
