import moment from 'moment-timezone';

import * as Globals from '../Globals';

export function formateDate(date, timezone = Globals.DEFAULT_TIMEZONE)
{
	let dateStr = undefined;

	try
	{
		dateStr = moment(date.getTime()).tz(timezone).format('ddd, MMM D h:mm a');
		//console.log("formateDate:" + dateStr);
	}
	catch (err)
	{
		console.log("ERROR: formateDate: " + err);
	}

	return dateStr;
}

export function parseDate(isoStr)
{
	let date = undefined;

	try
	{
		date = new Date(isoStr);
		//console.log("parseDate:" + JSON.stringify(date));
	}
	catch (err)
	{
		console.log("ERROR: parseDate: " + err);
	}

	return date;
}
